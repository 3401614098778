const tr = {
  home: {
    torkTour: "TORK TUR",
    monitoringControl: "TAKİP VE KONTROL",
    system: "SİSTEMİ",
    casingPipes: "MUHAFAZA BORULARI YERLEŞTİRME OPERASYONLARI İÇİN",
  },
  about: {
    abouts: "Hakkında",
    aboutDesc:
      "upu.jamlog tork-tur takip ve kayıt sistemi, kuyu güvenliğini sağlayan muhafaza borularının ve sondaj ekipmanlarının güvenli sıkma işlemlerinde kullanılmaktadır. Sıkma işleminde kullanılan hidrolik anahtarın (Power Tong) üzerine kolayca monte edilir. upu.jamlog Power Tong’un istenilen güvenli aralıkta muhafaza borularına zarar vermeden sıkma işleminin gerçekleştirilmesini ve alınan verilerin kayıt altında tutularak raporlanmasını sağlar. upu.jamlog tork-tur takip ve kayıt sistemi, sahada kullanılan her türlü hidrolik pnömatik sistemler ile uyumlu olarak çalışır.",
  },
  operator: {
    singleOperator: "Tek Operatör",
    singleDevice: "Tek Cihaz",
    proximity: "Proximity",
    nominalSwitch: "8mm Nominal Anahtarlama",
    divarterValve: "Yönlendirici Vana",
    barPressure: "450 Bar Basınç Dayanımlı Yönlendirici Vana",
    loadCell: "Load Cell",
    torqueTurnTracking: "Tork-Tur Takip ve Kontrol Cihazı",
  },
  technical: {
    technicals: "Teknik",
    features: "Özellikler",
    heightTechnology:
      "Yüksek teknolojiyle muhafaza boruları yerleştirme operasyonlarınızı gerçekleştirin",
    protectionAtex: "IP 67 Koruma ve ATEX Sertifikası",
    keypadDisplay: "12″-1000 nit LCD ve Tuş Takımlı Ekran",
    batterySystem: "Bataryalı Sistem",
    pressureDiverter: "450 Bar Basınç Dayanımlı Yönlendirici Vana",
    loadCellNumber: "Yük Hücresi (0-80.000 lb/ft)",
    nominalProximity: "Yakınlık (8mm Nominal Anahtarlama)",
    heightAngel: "Yüksekliği ve Açısı Ayarlanabilir Aparat",
    safeOperation: "24 V Güvenli Çalışma",
    bodyAluminium: "6061-T6 Alüminyum Gövde",
    staticElecktricity: "Statik Elektrik Koruması",
    allDevicess: "Tüm Cihazlardan",
    easyAccess: "Kolay Erişim",
  },
  webInterface: {
    interface: "Arayüzü",
    equipmentLibrary: "Ekipman Kütüphanesi Oluşturma",
    easyEquipment: "Ekipmanlarınızı kolayca kayıt altına almanızı sağlar.",
    realTimeMap: "Gerçek Zamanlı Harita Görünümü",
    operationMap: "Operasyonlarınızın harita üzerinden kontrolünü sağlar.",
    easyCreation: "Kolay İş Oluştuma",
    easyInterface: "Kolay arayüzüyle hızlı operasyon oluşturma olanağı sağlar.",
    casingLibrary: "Muhafaza Borusu Kütüphanesi Oluşturma",
    jobTracking: "İş Takip Listesi",
    saveCasing: "Muhafaza borularınızı kaydetme olanağı sağlar.",
    easyDocument:
      "Aktif, bekleyen ve geçmiş işleri sınıflandırma olanağı sağlar.",
    instantReport: "Anında Rapor Yükleme",
    anyDevice: "Anlık olarak her cihazdan rapor alma imkanı sağlar.",
    reportEditing: "Rapor Düzenleme ve Kaydetme",
    easyControl: "Raporlarınızın kolayca kontrolünü ve kaydedilmesini sağlar",
    fromAllDevicess: "Tüm Cihazlardan Kolay Erişim",
    easyMonitoring:
      "Telefon, tablet ve bilgisayar ile anlık erişim olanağı sağlar.",
  },
  systemInterface: {
    systems: "Sistem",
    onePersonel: "Tek Personel ",
    helpReduce: "Personel sayısını ve iş yükünü azaltmayı sağlar.",
    testMode: "Test Modu",
    providesSystem: "Sistem ve ekipman kontrol olanağı sağlar.",
    manualJob: "Manuel İş Oluşturma",
    manualJobExecution:
      "Erişim sorunlarından etkilenmeden iş yürütmeyi sağlar. ",
    reportLibrary: "Rapor Kütüphanesi",
    reportDesc: "Tüm geçmiş iş raporlarına kolay erişim sağlar.",
    userFriendly: "Kullanıcı Dostu Arayüzü",
    usefulInterface:
      "Anlaşılabilir ve kullanışlı arayüzü ile kullanım kolaylığı sağlar.",
    transferingJob: "Harici Bellekten İş Aktarma",
    malfunctions: "Arızalardan etkilenmeden operasyon devamlılığı sağlar.",
    digitalGraphic: "Dijital ve Analog Grafik",
    differentGraphic: "Farklı grafik seçenekleriyle çalışma kolaylığı sağlar.",
    reportTrecking: "Anlık Rapor Takibi",
    instantReportOperation:
      "Operasyon esnasında anlık raporlama imkanı sağlar.",
  },
  choose: {
    why: "Neden Bizi",
    chooseUs: "Seçmelisiniz ?",
    singlePersonel: "Tek Personelle İş Yürütme",
    easeOperation: "Yalnızca tek personel ile operasyon yürütme kolaylığı",
    modernInterface: "Modern Arayüz",
    operationReloated:
      "Operasyona dair tüm işlemleri modern arayüzü ile kontrol ve yönetim imkanı",
    instantRealTime: "Anında Gerçek Zamanlı Raporlama",
    instantRealTimeDesc: "Anlık ve geçmişe dönük tüm raporla erişim imkanı",
    atexCertification: "IP 67 Koruması & ATEX Sertifikası",
    atexCertificationDesc:
      "Operasyonel güvenlik için yüksek korumalı ekipman seçimi",
    assistantReport: "7/24 Asistan Desteği ve Eğitim",
    assistantReportDesc:
      "Cihaz-arayüz kullanım eğitimi ve olası arızalar için 7/24 destek.",

    locationGps: "GPS ile Konum Takibi",
    locationGpsDesc: "Gerçek zamanlı konum bilgisiyle iş ve ekipman takibi",
    contacttUs: "Bize",
    us: "Ulaşın",
  },
  navbar: {
    home: "Anasayfa",
    about: "Hakkımızda",
    technicalSpefications: "Özellikler",
    interfaces: " Arayüzler",
    whyChoose: " Neden Biz?",
    communication: "İletişim",
    login: "Giriş",
  },
};

export default tr;
