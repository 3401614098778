import React from "react";
import { PhoneTablet } from "../assets";
import { useTranslation } from "react-i18next";

const DevicePage = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-row mt-24 sm:px-8 md:px-12 lg:px-[80px] w-full justify-between xs:px-5 xs:h-[92px] items-center h-[208px] bg-[#30323D]  px-[112px]  ">
      <div className="flex flex-col w-[40%] xs:w-[60%] md:w-[80%] sm:w-[100%]">
        <p className="text-[40px] xs:text-[20px] sm:text-[24px] md:text-[32px] text-white font-medium">
          {t("technical:allDevicess")}
        </p>
        <p className="text-[#97CFD5] xs:text-[20px] sm:text-[24px] md:text-[32px] text-[40px] font-bold">
          {t("technical:easyAccess")}
        </p>
      </div>
      <img
        src={PhoneTablet}
        alt="PhoneTablet"
        loading="lazy"
        className="w-[120px] h-[120px] xs:w-12 xs:h-12 sm:w-16 sm:h-16 md:w-20 md:h-20"
      />
    </section>
  );
};

export default DevicePage;
