const en = {
  home: {
    torkTour: "TORQUE-TURN",
    monitoringControl: "MONITORING",
    system: "SYSTEM",
    casingPipes: "FOR CASING RUNNING OPERATIONS",
  },
  about: {
    abouts: "About",
    aboutDesc:
      "upu.jamlog is a torque-turn control recording system. It is used in the safe tightening operations of casing pipes and drilling equipment to ensure well security. It can be easily mounted on the hydraulic power tong. upu.jamlog system allows for safe tightening of casing pipes within the desired range without causing damage and enables the recording and reporting of the collected data. It is compatible with various hydraulic and pneumatic systems used in the field.",
  },
  operator: {
    singleOperator: "One Operator",
    singleDevice: "One Device",
    proximity: "Proximity",
    nominalSwitch: "8mm Nominal Switching",
    divarterValve: "Dump Valve",
    barPressure: "450 Bar Pressure Resistant Diverter Valve",
    loadCell: "Load Cell",
    torqueTurnTracking: "Torque-Turn Tracking and Control Device",
  },
  technical: {
    technicals: "Technical",
    features: "Specifications",
    heightTechnology:
      "Execute your casing running operations with high technology in collaboration ",
    protectionAtex: "IP 67 Protection and ATEX Certification",
    keypadDisplay: "12″-1000 nit LCD and Keypad Display",
    batterySystem: "Battery System",
    pressureDiverter: "450 Bar Pressure Resistant Dump Valve",
    loadCellNumber: "Load Cell (0-80.000 lb/ft)",
    nominalProximity: "Proximity (8mm Nominal Switching)",
    heightAngel: "Adjustable Height and Head Angle",
    safeOperation: "Safe Operation at 24 V",
    bodyAluminium: "6061-T6 Aluminium Body",
    staticElecktricity: "Static Electricity Protection",
    allDevicess: "Easy Access from",
    easyAccess: "Any Device",
  },
  webInterface: {
    interface: "Interface",
    equipmentLibrary: "Creating Equipment Library",
    easyEquipment: "Enables you to easily document your equipment.",
    realTimeMap: "Real Time Map View",
    operationMap:
      "Allows you to control your operations through a map interface.",
    easyCreation: "Easy Job Creation",
    easyInterface:
      "Enables to quickly create operations with its user-friendly interface.",
    casingLibrary: "Creating Case Pipe Library",
    jobTracking: "Job Tracking Lists",
    saveCasing: "Allows you to record your casing pipes.",
    easyDocument: "Enables to classify finished, pending, and upcoming tasks.",
    instantReport: "Instant Report Upload",
    anyDevice: "Allows you to instantly generate reports from any device.",
    reportEditing: "Report Editing and Saving ",
    easyControl: "Allows easy control and saving of your reports.",
    fromAllDevicess: "Easy Access from All Devices",
    easyMonitoring: "Enables easy monitoring and storage of your reports.",
  },
  systemInterface: {
    systems: "System",
    onePersonel: "Single Operator",
    helpReduce: "Helps reduce the number of personnel and workload.",
    testMode: "Test Mode",
    providesSystem: "Provides system and equipment control.",
    manualJob: "Enables job execution without being affected by access issues.",
    manualJobExecution:
      "Enables job execution without being affected by access issues.",
    reportLibrary: "Report Library",
    reportDesc: "Provides easy access to all past job reports.",
    userFriendly: "User Friendly Interface",
    usefulInterface:
      "Provides ease of use with its understandable and useful interface ",
    transferingJob: "Transferring Jobs from External Memory",
    malfunctions:
      "It provides operation continuity without being affected by malfunctions.",
    digitalGraphic: "Digital ve Analog Graphic",
    differentGraphic:
      "Provides ease of working with different graphic options.",
    reportTrecking: "Instant Report Tracking",
    instantReportOperation:
      "It provides instant reporting during the operation.",
  },
  choose: {
    why: "Why",
    chooseUs: "Choose Us ?",
    singlePersonel: "Executing Work with Single Personnel",
    easeOperation: "Ease of operation with only one personnel",
    modernInterface: "Modern Interface",
    operationReloated:
      "The ability to control and manage all operations related to the operation with a modern interface",
    instantRealTime: "Instant Real Time Reporting",
    instantRealTimeDesc:
      "Access to real-time and retrospective report generation capabilities.",
    atexCertification: "IP 67 Protection & ATEX Certification",
    atexCertificationDesc:
      "Selection of highly protected equipment for operational safety",
    assistantReport: "7/24 Assistant Support and Training",
    assistantReportDesc:
      "Device-interface usage training and 24/7 support for possible malfunctions.",
    locationGps: "Location Tracking with GPS",
    locationGpsDesc:
      "Work and equipment tracking with real-time location information",
    contacttUs: "Contact",
    us: "Us",
  },
  navbar: {
    home: "Home",
    about: "About",
    technicalSpefications: "Specifications",
    interfaces: "Interfaces",
    whyChoose: "Why Us?",
    communication: "Contact",
    login: "Login",
  },
};

export default en;
