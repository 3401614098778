import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
  BritainFlag,
  BurgerIcon,
  JamlogLogo,
  TurkishFlag,
  UpuJamlog,
  UpuWhiteLogo,
} from "../assets/index";
import { MdLanguage } from "react-icons/md";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  const [navbar, setNavbar] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(i18next.language);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const plans = [
    {
      name: "TR",
      value: "tr",
      icon: <TurkishFlag />,
    },
    {
      name: "EN",
      value: "en",
      icon: <BritainFlag />,
    },
  ];
  return (
    <>
      <nav className="w-full h-[80px] flex flex-row gap-8 items-center bg-white px-[112px] fixed top-0 z-[9999] border-b xs:px-5">
        <div
          id="navbar"
          className="flex flex-row items-center gap-8 lg:gap-4 md:gap-2 sm:gap-2"
        >
          <div className="flex items-start ">
            <a
              className="text-gray-600  font-semibold text-base cursor-pointer"
              onClick={() =>
                document.getElementById("home").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              <JamlogLogo className="sm:w-[100px]" />
            </a>
          </div>

          <div className="flex flex-row w-full items-center gap-20 ml-24 sm:ml-0 sm:gap-2 xs:hidden lg:gap-3 lg:ml-4 md:ml-0 md:gap-2 ">
            <a
              className="text-gray-600  font-semibold text-base lg:text-center sm:text-[12px] sm:text-center md:text-center md:text-xs cursor-pointer"
              onClick={() =>
                document.getElementById("home").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              {t("navbar:home")}
            </a>
            <a
              className="text-gray-600  font-semibold text-base sm:text-[12px] sm:text-center lg:text-center md:text-xs  md:text-center cursor-pointer"
              onClick={() =>
                document.getElementById("page").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              {t("navbar:about")}
            </a>

            <a
              className="text-gray-600  font-semibold text-base lg:text-center md:text-xs sm:text-[12px] sm:text-center md:text-center cursor-pointer"
              onClick={() =>
                document.getElementById("whyJamlog").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              {t("navbar:technicalSpefications")}
            </a>
            <a
              className="text-gray-600  font-semibold text-base lg:text-center md:text-xs sm:text-[12px] sm:text-center  md:text-center cursor-pointer"
              onClick={() =>
                document.getElementById("howWork").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              {t("navbar:interfaces")}
            </a>

            <a
              className="text-gray-600  font-semibold text-base  lg:text-center md:text-xs sm:text-[12px] sm:text-center  md:text-center cursor-pointer"
              onClick={() =>
                document.getElementById("jamlogPage").scrollIntoView({
                  behavior: "smooth",
                  inline: "start",
                })
              }
            >
              {t("navbar:whyChoose")}
            </a>
            <a
              className="text-gray-600  font-semibold text-base lg:text-center md:text-xs sm:text-[12px] sm:text-center md:text-center cursor-pointer"
              onClick={() =>
                document.getElementById("footerPage").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              {t("navbar:communication")}
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3 ml-auto xs:hidden ">
          <div>
            <button
              onClick={() => {
                setVisibleLanguage(!visibleLanguage);
              }}
              className="w-11 h-11 bg-[#FFFFFF] flex items-center justify-center border-gray-300 shadow rounded-lg cursor-pointer"
            >
              <MdLanguage color="#344054" size={24} className="sm:w-6" />
            </button>

            {visibleLanguage && (
              <div className="relative">
                <div className="absolute top-2 right-0 z-50 bg-white">
                  <RadioGroup
                    value={selectLanguage}
                    className="w-full "
                    onChange={(e) => {
                      setSelectLanguage(e);
                      i18next.changeLanguage(e);
                    }}
                  >
                    <div className="relative flex flex-col gap-2 bg-white rounded-md p-2 border border-gray-200 shadow">
                      {plans.map((e, i) => (
                        <RadioGroup.Option
                          key={e.name}
                          value={e.value}
                          className={({ checked }) =>
                            classNames(
                              i === 0 ? "rounded-tl-md rounded-tr-md" : "",
                              i === e.length - 1
                                ? "rounded-bl-md rounded-br-md"
                                : "",
                              e.value === selectLanguage
                                ? "bg-white border-gray-200 z-10"
                                : "border-gray-200",
                              "relative border rounded-lg px-6 py-3 flex flex-col cursor-pointer  select-none focus:outline-none hover:bg-primary-50"
                            )
                          }
                          onClick={() => setVisibleLanguage(false)}
                        >
                          {({ active, checked }) => (
                            <>
                              <span className="flex items-center text-sm justify-between w-full ">
                                <div className="flex items-center gap-x-2 ">
                                  <div> {e.icon}</div>
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      e.value === selectLanguage
                                        ? "text-[#344054]"
                                        : "text-[#475467]",
                                      "mr-3 font-medium"
                                    )}
                                  >
                                    {e.name}
                                  </RadioGroup.Label>
                                </div>
                                <span
                                  className={classNames(
                                    e.value === selectLanguage
                                      ? "bg-[#00727F] border-transparent"
                                      : "bg-white border-[#008999]",
                                    active
                                      ? "ring-2 ring-offset-2 ring-primary-600"
                                      : "",
                                    "h-3 w-3 rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5 " />
                                </span>
                              </span>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            )}
          </div>
          <a
            href="https://jamlog.upu.io/"
            target="_blank"
            className={`flex text-[16px] sm:text-[12px] text-white flex-row items-center justify-center bg-[#00727F] rounded-lg w-[80px] h-[44px] `}
          >
            {t("navbar:login")}
          </a>
        </div>

        <div className="hidden xs:flex  items-center ml-auto  ">
          <button onClick={() => setNavbar(!navbar)} id="burgerButton">
            <BurgerIcon />
          </button>
        </div>
      </nav>
      {/* collapsable menu */}
      <div
        className={`flex flex-col w-full px-5 py-3 gap-3 xs:mt-[80px] fixed bg-white z-[9999999] ${
          navbar ? "block" : "hidden"
        }`}
      >
        <a
          className="text-gray-600 h-8 border-b font-semibold text-base cursor-pointer"
          onClick={() =>
            document.getElementById("home").scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {t("navbar:home")}
        </a>
        <a
          className="text-gray-600 h-8 border-b font-semibold text-base cursor-pointer"
          onClick={() =>
            document.getElementById("page").scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {t("navbar:about")}
        </a>

        <a
          className="text-gray-600 h-8 border-b font-semibold text-base cursor-pointer"
          onClick={() =>
            document.getElementById("whyJamlog").scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {t("navbar:technicalSpefications")}
        </a>
        <a
          className="text-gray-600 h-8 border-b font-semibold text-base cursor-pointer"
          onClick={() => {
            document.getElementById("accessPage").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        >
          {t("navbar:interfaces")}
        </a>

        <a
          className="text-gray-600 h-8 border-b font-semibold text-base cursor-pointer"
          onClick={() =>
            document.getElementById("jamlogPage").scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {t("navbar:whyChoose")}
        </a>
        <a
          className="text-gray-600 h-8 border-b  font-semibold text-base cursor-pointer"
          onClick={() =>
            document.getElementById("footerPage").scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          {t("navbar:communication")}
        </a>
      </div>
    </>
  );
};

export default Navbar;
