import React from "react";
import { Facebook, FooterLeft, Linkedln, Twitter } from "../assets";
import { useTranslation } from "react-i18next";

const FooterPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer
        id="footerPage"
        className="w-full h-[232px] flex-row items-start relative mt-[112px] py-5  bg-[#30323D] xs:hidden"
      >
        <div className="flex flex-row ml-[112px] lg:ml-10 md:ml-12 sm:ml-6 items-start relative">
          <div className="flex flex-col ">
            <div className="flex flex-row gap-1 items-center ">
              <p className="text-[30px] sm:text-[24px] font-semibold text-white">
                {t("choose:contacttUs")}
              </p>
              <p className="text-[30px] sm:text-[24px] font-semibold text-white border-b-[4px] border-b-[#4BA4B0] mt-1">
                {t("choose:us")}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-12 justify-center ml-[20%] lg:ml-32 md:ml-16 sm:ml-6 mt-4 sm:gap-4">
            <div className="flex items-start  flex-row gap-20 md:gap-5 sm:gap-2 md:w-full sm:flex-wrap">
              <p className="text-[14px] text-white font-bold">
                T : +90 (312) 394 57 77
              </p>
              <p className="text-[14px] text-white font-bold">
                W : upujamlog.com
              </p>
              <p className="text-[14px] text-white font-bold">
                E : jamlog@upu.io
              </p>
            </div>
            <div className="flex flex-row gap-12  md:ml-0 sm:flex-col sm:gap-2 sm:ml-0">
              <p className="w-[30%] text-white text-[14px] font-normal md:w-[50%] sm:w-full">
                Tekno Park Ankara 2224. Cd. No :1/C-214 Yenimahalle / Ankara /
                Türkiye
              </p>
              <p className="w-[30%] text-white text-[14px] font-normal md:w-[50%] sm:w-full">
                Turgut Özal Mah. 1953 No: 22A Yenimahalle / Ankara / Türkiye
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-11 items-center absolute -bottom-12 right-12 sm:-bottom-12">
            <a
              href="https://www.linkedin.com/company/96041696/admin/feed/posts/"
              rel="noreferrer"
              target={"_blank"}
            >
              <Linkedln />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61550834351025"
              rel="noreferrer"
              target={"_blank"}
            >
              <Facebook />
            </a>

            {/* <Twitter /> */}
          </div>
        </div>

        <img
          src={FooterLeft}
          alt="FooterLeft"
          loading="lazy"
          className=" h-[123px] w-[226px] absolute left-0 bottom-0 sm:w-[130px] sm:h-[55px]"
        />
      </footer>
      {/* ------------Mobile View-------------------- */}
      <div
        id="footerPage"
        className="flex-col px-5 hidden xs:flex items-start w-full h-[335px] bg-[#30323D] py-6 relative"
      >
        <div className="flex flex-row gap-1.5 items-center ">
          <p className="text-[20px] font-semibold text-white">
            {t("choose:contacttUs")}
          </p>
          <p className="text-[20px] font-semibold text-white border-b-[4px] border-b-[#4BA4B0] mt-1.5">
            {t("choose:us")}
          </p>
        </div>
        <div className="flex flex-col gap-3 items-start mt-6">
          <p className="text-[12px] text-white font-bold">
            T : +90 (312) 394 57 77
          </p>
          <p className="text-[12px] text-white font-bold">W : upujamlog.com</p>
          <p className="text-[12px] text-white font-bold">E : jamlog@upu.io</p>
        </div>
        <div className="flex flex-col items-start gap-3 mt-6">
          <p className="w-full text-white text-[12px] font-normal">
            Tekno Park Ankara 2224. Cd. No :1/C-214 Yenimahalle / Ankara /
            Türkiye
          </p>
          <p className="w-full text-white text-[12px] font-normal">
            Turgut Özal Mah. 1953 No: 22A Yenimahalle / Ankara / Türkiye
          </p>
        </div>
        <div className="flex flex-row gap-11 ml-auto items-center mt-6">
          <Linkedln width={24} height={24} />
          <Facebook width={24} height={24} />
          <Twitter width={24} height={24} />
        </div>
        <img
          src={FooterLeft}
          alt="FooterLeft"
          loading="lazy"
          className=" h-[48px] w-[92px] absolute left-0 bottom-0"
        />
      </div>
    </>
  );
};

export default FooterPage;
