import Navbar from "./components/Navbar";
import "./index.css";
import AccessPage from "./pages/AccessPage";
import DevicePage from "./pages/DevicePage";
import FooterPage from "./pages/FooterPage";
import Home from "./pages/Home";
import HowWorkPage from "./pages/HowWorkPage";
import JamlogPage from "./pages/JamlogPage";
import Page from "./pages/Page";
import UpuJamlogPage from "./pages/UpuJamlogPage";
import WhyUpu from "./pages/WhyUpu";

function App() {
  return (
    <main className="w-full h-full flex flex-col overflow-hidden">
      <Navbar />
      <Home />
      <Page />
      <UpuJamlogPage />
      <WhyUpu />
      <DevicePage />
      <HowWorkPage />
      <AccessPage />
      <JamlogPage />
      <FooterPage />
    </main>
  );
}

export default App;
