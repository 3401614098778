import React from "react";
import {
  DesktopGraph,
  InputOutput,
  Library,
  ManualCasing,
  Memory,
  OnePersonel,
  ReportTrecking,
  TestMode,
  UserInterface,
} from "../assets";
import { useTranslation } from "react-i18next";

const AccessPage = () => {
  const { t } = useTranslation();
  const jamlogData = [
    {
      icon: OnePersonel,
      title: t("systemInterface:onePersonel"),
      subtitle: t("systemInterface:helpReduce"),
    },
    {
      icon: TestMode,
      title: t("systemInterface:testMode"),
      subtitle: t("systemInterface:providesSystem"),
    },
    {
      icon: ManualCasing,
      title: t("systemInterface:manualJob"),
      subtitle: t("systemInterface:manualJobExecution"),
    },
    {
      icon: Library,
      title: t("systemInterface:reportLibrary"),
      subtitle: t("systemInterface:reportDesc"),
    },
    {
      icon: UserInterface,
      title: t("systemInterface:userFriendly"),
      subtitle: t("systemInterface:usefulInterface"),
    },
    {
      icon: Memory,
      title: t("systemInterface:transferingJob"),
      subtitle: t("systemInterface:malfunctions"),
    },

    {
      icon: InputOutput,
      title: t("systemInterface:digitalGraphic"),
      subtitle: t("systemInterface:differentGraphic"),
    },
    {
      icon: ReportTrecking,
      title: t("systemInterface:reportTrecking"),
      subtitle: t("systemInterface:instantReportOperation"),
    },
  ];
  return (
    <>
      <section
        id="accessPage"
        className="w-full h-full xs:h-full pt-0 pb-[80px] flex flex-col items-start bg-white xs:hidden"
      >
        <div className="flex flex-row xs:flex-col-reverse  justify-between w-full px-[112px] lg:px-10 md:px-12 sm:px-5 xs:px-0   xs:items-start">
          <img
            src={DesktopGraph}
            alt="DesktopGraph"
            loading="lazy"
            style={{
              width: "40%",
              height: "auto",
              marginRight: "auto",
            }}
          />

          <div
            style={{
              clipPath:
                "polygon(65% 0, 100% 0, 100% 66%, 60% 100%, 0 100%, 0 58%)",
            }}
            className=" h-[296px] w-[365px] xs:w-[150px] xs:h-[122px] lg:w-[300px] lg:h-[230px] md:w-[250px] md:h-[180px] sm:h-[130px] sm:w-[200px] xs:px-0 bg-[#DBEEF1] flex items-center justify-center"
          >
            <div className="flex flex-col translate-x-6 xs:translate-x-4">
              <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[22px] text-[#005F6A] font-medium">
                {t("systemInterface:systems")}
              </p>
              <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[22px] text-[#004D56] font-bold">
                {t("webInterface:interface")}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap w-full  gap-y-16 mt-4 px-[112px] pt-6 xs:px-5 xs:flex-col xs:gap-y-2 lg:px-10 md:px-4 sm:px-6">
          {jamlogData?.map((item, index) => {
            return (
              <>
                <div className="flex flex-row pt-8 xs:pt-0 xs:w-full h-[82px] w-[33%] sm:w-[50%] md:w-[50%]">
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                    }}
                    className="flex items-center justify-center bg-[#DBEEF1] w-11 h-10 min-w-[44px] min-h-[40px] mt-0.5"
                  >
                    <item.icon />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[16px] xs:text-[14px] sm:text-[14px] xs:leading-5 xs:w-full leading-[30px] font-semibold text-[#30323D] w-[85%]">
                      {item.title}
                    </p>
                    <p className="text-[14px] xs:text-12px sm:text-xs xs:w-full text-[#404041] font-light w-[95%]">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
      {/* {mobile view} */}
      <section
        id="accessPage"
        className="hidden flex-col items-start w-full h-full xs:flex"
      >
        <div className="flex flex-col-reverse  justify-between w-full px-0 mt-12 items-start">
          <img
            src={DesktopGraph}
            alt="Desktop"
            className="px-5 mt-4 "
            loading="lazy"
          />

          <div
            style={{
              clipPath:
                "polygon(65% 0, 100% 0, 100% 66%, 60% 100%, 0 100%, 0 58%)",
            }}
            className=" w-[150px] h-[122px] bg-[#DBEEF1] flex items-center justify-center"
          >
            <div className="flex flex-col translate-x-4">
              <p className="text-[20px] text-[#005F6A] font-medium">
                {t("systemInterface:systems")}
              </p>
              <p className="text-[20px] text-[#004D56] font-bold">
                {t("webInterface:interface")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mt-4  pt-6 px-5 gap-y-2 ">
          {jamlogData?.map((item, index) => {
            return (
              <>
                <div className="flex flex-row pt-2 w-full h-[82px]">
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                    }}
                    className="flex items-center justify-center bg-[#DBEEF1] w-11 h-10 min-w-[44px] min-h-[40px] mt-0.5"
                  >
                    <item.icon />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[14px] leading-5 w-full font-semibold text-[#30323D] ">
                      {item.title}
                    </p>
                    <p className="text-[12px] w-full text-[#404041] font-light ">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default AccessPage;
