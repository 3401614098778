import React from "react";
import {
  Casing,
  CasingList,
  Desktop,
  Device,
  Library,
  Map,
  Report,
  Tally,
  editReport,
} from "../assets";
import { useTranslation } from "react-i18next";

const HowWorkPage = () => {
  const { t } = useTranslation();
  const jamlogData = [
    {
      icon: Library,
      title: t("webInterface:equipmentLibrary"),
      subtitle: t("webInterface:easyEquipment"),
    },
    {
      icon: Map,
      title: t("webInterface:realTimeMap"),
      subtitle: t("webInterface:operationMap"),
    },
    {
      icon: Casing,
      title: t("webInterface:easyCreation"),
      subtitle: t("webInterface:easyInterface"),
    },
    {
      icon: Tally,
      title: t("webInterface:casingLibrary"),
      subtitle: t("webInterface:saveCasing"),
    },
    {
      icon: CasingList,
      title: t("webInterface:jobTracking"),
      subtitle: t("webInterface:easyDocument"),
    },
    {
      icon: Report,
      title: t("webInterface:instantReport"),
      subtitle: t("webInterface:anyDevice"),
    },

    {
      icon: editReport,
      title: t("webInterface:reportEditing"),
      subtitle: t("webInterface:easyControl"),
    },
    {
      icon: Device,
      title: t("webInterface:fromAllDevicess"),
      subtitle: t("webInterface:fromAllDevicess"),
    },
  ];
  return (
    <>
      <section
        id="howWork"
        className="w-full h-full xs:h-full py-[80px] flex flex-col items-start bg-white xs:hidden"
      >
        <div className="flex flex-row justify-between w-full px-[112px] md:px-8 sm:px-5 lg:px-10 xs:px-0  xs:items-start  xs:flex-col ">
          <div
            style={{
              clipPath:
                "polygon(65% 0, 100% 0, 100% 66%, 60% 100%, 0 100%, 0 58%)",
            }}
            className=" h-[296px] w-[365px] xs:w-[150px] xs:h-[122px] sm:w-[200px] sm:h-[150px] lg:w-[300px] lg:h-[230px] xs:px-0 bg-[#F1F1F2] flex items-center  justify-center"
          >
            <div className="flex flex-col translate-x-6 xs:translate-x-4">
              <p className="text-[40px] xs:text-[20px] sm:text-[24px] text-[#30323D] md:text-[28px] font-medium">
                Web
              </p>
              <p className="text-[40px] xs:text-[20px] sm:text-[24px] md:text-[28px] text-[#30323D] font-bold">
                {t("webInterface:interface")}
              </p>
            </div>
          </div>

          <img
            src={Desktop}
            alt="Desktop"
            loading="lazy"
            style={{
              width: "40%",
              height: "auto",
              marginLeft: "auto",
            }}
          />
        </div>
        <div className="flex flex-row flex-wrap w-full  gap-y-16 mt-12 px-[112px] md:px-6   lg:px-10 sm:px-4  xs:px-5 xs:flex-col xs:gap-y-2  ">
          {jamlogData?.map((item, index) => {
            return (
              <>
                <div className="flex flex-row pt-2 xs:pt-0  h-[82px] w-[33%] xs:w-full sm:w-[50%] md:w-[50%]">
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                    }}
                    className="flex items-center justify-center bg-[#DBEEF1] w-11 h-10 min-w-[44px] min-h-[40px] mt-0.5"
                  >
                    <item.icon />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[16px] xs:text-[14px] sm:text-[14px] leading-[30px] xs:leading-5 font-semibold text-[#30323D] w-[85%] xs:w-full">
                      {item.title}
                    </p>
                    <p className="text-[14px] xs:text-12px sm:text-xs text-[#404041] font-light w-[90%] xs:w-full md:w-[95%]">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
      {/* {mobile view} */}
      <div
        id="howWork"
        className=" flex-col items-start w-full h-full hidden xs:flex"
      >
        <div className="flex flex-col  w-full px-0 mt-12 xs:items-start ">
          <div
            style={{
              clipPath:
                "polygon(65% 0, 100% 0, 100% 66%, 60% 100%, 0 100%, 0 58%)",
            }}
            className="w-[150px] h-[122px]  bg-[#F1F1F2] flex items-center  justify-center"
          >
            <div className="flex flex-col translate-x-4">
              <p className="text-[20px]  text-[#30323D] font-medium">Web</p>
              <p className="text-[20px] text-[#30323D] font-bold">
                {t("webInterface:interface")}
              </p>
            </div>
          </div>
          <img src={Desktop} alt="Desktop" className="px-5 mt-4" />
        </div>
        <div className="flex w-full mt-12 px-5 flex-col gap-y-2 ">
          {jamlogData?.map((item, index) => {
            return (
              <>
                <div className="flex flex-row h-[82px] w-full">
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                    }}
                    className="flex items-center justify-center bg-[#DBEEF1] w-11 h-10 min-w-[44px] min-h-[40px] mt-0.5"
                  >
                    <item.icon />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[14px] leading-5 font-semibold text-[#30323D] w-full">
                      {item.title}
                    </p>
                    <p className="text-[12px]  text-[#404041] font-light w-full">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HowWorkPage;
