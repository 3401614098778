import React from "react";
import { JamlogKitImage, JamlogRightOperator, OneOperator } from "../assets";
import { useTranslation } from "react-i18next";

const UpuJamlogPage = () => {
  const { t } = useTranslation();
  const jamlogData = [
    {
      number: "1",
      title: t("operator:proximity"),
      subtitle: t("operator:nominalSwitch"),
    },
    {
      number: "2",
      title: t("operator:divarterValve"),
      subtitle: t("operator:barPressure"),
    },
    {
      number: "3",
      title: t("operator:loadCell"),
      subtitle: "0-80.000 lb/ft",
    },
    {
      number: "4",
      title: "upu.jamlog",
      subtitle: t("operator:torqueTurnTracking"),
    },
  ];
  return (
    <>
      <section
        id="upuJamlogPage"
        className="w-full h-full px-[112px] sm:px-8 md:px-12 lg:px-[80px]  pt-[80px] flex flex-col items-start relative bg-white xs:px-5 xs:py-6 xs:hidden"
      >
        <div className="flex flex-col w-full mt-2 items-start">
          <p className="text-[40px] xs:text-[20px] sm:text-[24px] md:text-[28px] text-[#30323D] font-medium">
            {t("operator:singleOperator")}
          </p>
          <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[24px] text-[#30323D] font-bold">
            {t("operator:singleDevice")}
          </p>
        </div>
        <div className="flex flex-row flex-wrap w-[60%] lg:gap-5 sm:w-full md:w-[600px] xs:flex-col xs:flex-nowrap xs:gap-y-4 xs:mt-0 justify-between gap-y-11 mt-4">
          {jamlogData?.map((item, index) => {
            return (
              <>
                <div className="flex w-[48%] sm:w-[50%] flex-row pt-2 xs:pt-4 h-[82px] xs:h-[40px]">
                  <div
                    style={{
                      clipPath:
                        "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                    }}
                    className="flex items-center justify-center bg-[#DBEEF1] w-11 h-10 xs:w-6 xs:h-5 xs:min-w-[24px] xs:min-h-[20px] min-w-[44px] min-h-[40px] mt-0.5"
                  >
                    <p className="text-[24px] xs:text-[14px] font-semibold text-[#30323D]">
                      {item.number}
                    </p>
                  </div>
                  <div className="flex flex-col ml-6 xs:ml-2">
                    <p className="text-[20px] xs:text-[14px] sm:text-[14px] leading-[30px] xs:leading-4 font-semibold text-[#30323D]">
                      {item.title}
                    </p>
                    <p className="text-base xs:text-[12px] lg:w-[80%] sm:text-[12px] text-[#30323D] font-light">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <figure>
          <img
            src={OneOperator}
            alt="OneOperator"
            loading="lazy"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </figure>
      </section>
      {/* ------------Mobile View-------------------- */}
      <div
        id="upuJamlogPage"
        className="flex-col items-start hidden xs:flex w-full h-full py-6"
      >
        <div className="flex flex-col w-full mt-2 px-5 items-start">
          <p className="text-[20px] text-[#30323D] font-medium">
            {t("operator:singleOperator")}
          </p>
          <p className="text-[20px] text-[#30323D] font-bold">
            {t("operator:singleDevice")}
          </p>
          <div className="flex flex-col gap-4 items-start w-full mt-4">
            {jamlogData?.map((item, index) => {
              return (
                <>
                  <div className="flex w-full flex-row pt-2 h-[40px]">
                    <div
                      style={{
                        clipPath:
                          "polygon(25% 0%, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                      }}
                      className="flex items-center justify-center bg-[#DBEEF1] w-6 h-5 min-w-[24px] min-h-[20px] mt-0.5"
                    >
                      <p className="text-[14px] font-semibold text-[#30323D]">
                        {item.number}
                      </p>
                    </div>
                    <div className="flex flex-col ml-2">
                      <p className="text-[14px] leading-4 font-semibold text-[#30323D]">
                        {item.title}
                      </p>
                      <p className="text-[12px] text-[#30323D] font-light">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col items-center w-full gap-4">
          <img
            src={JamlogRightOperator}
            alt="JamlogRightOperator"
            loading="lazy"
            className="mt-4"
          />
          <img src={JamlogKitImage} alt="JamlogKitImage" className=" px-5" />
        </div>
      </div>
    </>
  );
};

export default UpuJamlogPage;
