import React from "react";
import {
  ChevronIcon,
  TechnicalEnglish,
  TechnicalTurkish,
  TechnicalVector,
} from "../assets";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const WhyUpu = ({ selectLanguage }) => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("technical:protectionAtex"),
    },
    {
      title: t("technical:keypadDisplay"),
    },
    {
      title: t("technical:batterySystem"),
    },
    {
      title: t("technical:pressureDiverter"),
    },
    {
      title: t("technical:loadCellNumber"),
    },
    {
      title: t("technical:nominalProximity"),
    },
    {
      title: t("technical:heightAngel"),
    },
    {
      title: "GPS",
    },
    {
      title: t("technical:safeOperation"),
    },
    {
      title: t("technical:bodyAluminium"),
    },
    {
      title: t("technical:staticElecktricity"),
    },
  ];

  console.log(i18next, "i1888");

  return (
    <>
      <section
        id="whyJamlog"
        className="w-full h-full px-[112px] sm:px-8 md:px-12 lg:px-[80px]  py-[80px] flex xs:px-5 xs:mt-6 xs:flex-col  items-start relative   bg-white xs:hidden"
      >
        <div className="flex flex-col w-1/2 md:w-full sm:w-full relative">
          <div className="flex flex-col w-full items-start">
            <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[24px] text-[#30323D] font-medium">
              {t("technical:technicals")}
            </p>
            <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[24px] text-[#30323D] font-bold">
              {t("technical:features")}
            </p>
          </div>
          <div className="flex flex-col items-start gap-4">
            {data?.map((item, index) => {
              return (
                <>
                  <div className="flex flex-col mt-4 w-[80%] lg:w-full md:w-full sm:w-full">
                    <div
                      className="w-full  h-8 rounded-lg  px-3 py-1"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#4DACB8" : "#F1F1F2",
                      }}
                    >
                      <p className="text-[16px] font-medium text-[#404041]">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {/* <div>
            <img
              src={ChevronIcon}
              alt="ChevronIcon"
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div> */}
          <img
            style={{
              loading: "lazy",
            }}
            src={ChevronIcon}
            alt="ChevronIcon"
            loading="lazy"
            className="absolute h-[282px] w-[467px] left-4 -bottom-24 md:left-20 lg:left-24 sm:left-16"
          />
        </div>
        <div className="w-full flex flex-col relative ">
          {/* <img
            src={TechnicalVector}
            alt="TechnicalVector"
            loading="lazy"
            className="absolute -left-16 h-[252px] w-[311px] top-0 lg:w-[200px] lg:h-[200px] lg:left-24 md:left-16 md:w-[150px] md:h-[150px] sm:w-[100px] sm:h-[120px] sm:left-20"
          /> */}
          <div>
            <img
              src={TechnicalVector}
              alt="TechnicalVector"
              loading="lazy"
              style={{
                width: "30%",
                height: "auto",
                marginLeft: 24,
              }}
            />
          </div>
          <div className="flex items-start ml-[168px] flex-col mt-12 lg:mt-10 lg:ml-[180px] absolute">
            <div className="font-medium text-[24px] sm:text-[14px] sm:w-full md:w-full lg:w-full text-[#30323D] w-[60%]">
              {t("technical:heightTechnology")}
            </div>
          </div>
        </div>

        {i18next.language == "tr" ? (
          <img
            src={TechnicalTurkish}
            alt="TechnicalTurkish"
            loading="lazy"
            className="absolute  right-32 h-[558px] w-[531px] bottom-44 lg:h-[400px] lg:w-[400px] lg:right-8 md:right-4 md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[200px] sm:right-5"
          />
        ) : (
          <img
            src={TechnicalEnglish}
            alt="TechnicalEnglish"
            loading="lazy"
            className="absolute  right-32 h-[558px] bottom-16 lg:h-[400px] lg:w-[500px] lg:right-8 md:right-2  md:bottom-32 md:h-[300px] md:w-[270px] sm:h-[300px] sm:w-[200px] sm:right-3 sm:bottom-44"
          />
        )}
      </section>
      {/* ------------Mobile View-------------------- */}
      <div
        id="whyJamlog"
        className=" flex-col items-start  w-full h-full hidden xs:flex py-6 relative"
      >
        <p className="text-[20px] xs:text-[20px] text-[#30323D] font-medium px-5">
          {t("technical:technicals")}
        </p>
        <p className="text-[20px] xs:text-[20px] text-[#30323D] font-bold px-5">
          {t("technical:features")}
        </p>
        {data?.map((item, index) => {
          return (
            <>
              <div className="flex flex-col mt-4 w-full px-5">
                <div
                  className="w-full h-[26px] rounded-lg  px-3 py-1"
                  style={{
                    backgroundColor: index % 2 == 0 ? "#4DACB8" : "#F1F1F2",
                  }}
                >
                  <p className="text-[12px] font-medium text-[#404041]">
                    {item.title}
                  </p>
                </div>
              </div>
            </>
          );
        })}
        <div className="flex items-start ml-auto h-[216px] w-full relative">
          <img
            src={ChevronIcon}
            alt="ChevronIcon"
            className="absolute bottom-24 -right-12"
          />
        </div>
        <div className="w-full flex flex-col items-start relative ">
          <div className="flex items-start h-[155px] w-[126px] relative">
            <img
              src={TechnicalVector}
              alt="TechnicalVector"
              className="absolute  -left-12 "
            />
          </div>

          <div className="flex items-start ml-5 mt-2 flex-col absolute">
            <div className="font-medium text-[24px] text-[#30323D] w-full">
              {t("technical:heightTechnology")}
            </div>
          </div>
        </div>
        <img
          src={TechnicalTurkish}
          alt="TechicalTurkish"
          className=" left-0 pr-5 "
        />
      </div>
    </>
  );
};

export default WhyUpu;
