import React from "react";
import { HomeImage } from "../assets";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <header
        id="home"
        className="w-screen h-screen bg-white flex-row flex relative  xs:hidden"
      >
        <div className=" flex w-full flex-col items-start mt-[120px] px-[112px] sm:px-8 md:px-12 lg:px-[80px]">
          <p className="text-[44px] lg:text-[36px] md:text-[32px] sm:text-[24px] xs:text-[24px] text-[#101828] font-bold">
            {t("home:torkTour")}
          </p>
          <p className="text-[44px] lg:text-[36px] md:text-[32px] xs:text-[24px] sm:text-[24px] text-[#008999] font-bold">
            {t("home:monitoringControl")}
          </p>
          <p className="text-[44px] lg:text-[36px] md:text-[32px] xs:text-[24px] sm:text-[24px] text-[#101828] font-bold">
            {t("home:system")}
          </p>
          <p className="text-[20px] md:text-[14px] lg:text-[16px] sm:text-[14px] xs:text-[14px] text-[#475467] font-medium">
            {t("home:casingPipes")}
          </p>
        </div>
        <figure>
          <img
            style={
              {
                // loading: "lazy",
              }
            }
            // src={HomeImage}
            src={"https://cdn.upu.io/7cde8017-d7bf-4ecb-8ef4-2c42e14f5c48"}
            alt="HomeImage"
            className=" absolute h-[90%]  right-0 top-20  w-[81%] "
          />
        </figure>
      </header>
      {/* mobile view */}
      <div
        id="home"
        className="flex-col items-start w-full xs:flex h-full mt-[120px] hidden"
      >
        <div className="flex flex-col items-start px-5">
          <p className="text-[24px]  text-[#101828] font-bold">
            {t("home:torkTour")}
          </p>
          <p className="text-[24px]  text-[#008999] font-bold">
            {t("home:monitoringControl")}
          </p>
          <p className="text-[24px]  text-[#101828] font-bold">
            {t("home:system")}
          </p>
          <p className="text-[14px]  text-[#475467] font-medium">
            {t("home:casingPipes")}
          </p>
        </div>
        <img
          src={HomeImage}
          alt="HomeImage"
          className="  mt-4"
          loading="lazy"
        />
      </div>
    </>
  );
};

export default Home;
