import React from "react";
import {
  Atex,
  Education,
  Friends,
  Gps,
  JamlogEquipmentCard,
  JamunitImage,
  ModernInterface,
  RealReport,
} from "../assets";
import { useTranslation } from "react-i18next";

const JamlogPage = () => {
  const { t } = useTranslation();
  const jamlogData = [
    {
      icon: Friends,
      title: t("choose:singlePersonel"),
      subtitle: t("choose:easeOperation"),
    },
    {
      icon: ModernInterface,
      title: t("choose:modernInterface"),
      subtitle: t("choose:operationReloated"),
    },
    {
      icon: RealReport,
      title: t("choose:instantRealTime"),
      subtitle: t("choose:instantRealTimeDesc"),
    },
  ];
  const data = [
    {
      icons: Atex,
      titles: t("choose:atexCertification"),
      subtitles: t("choose:atexCertificationDesc"),
    },
    {
      icons: Education,
      titles: t("choose:assistantReport"),
      subtitles: t("choose:assistantReportDesc"),
    },
    {
      icons: Gps,
      titles: t("choose:locationGps"),
      subtitles: t("choose:locationGpsDesc"),
    },
  ];
  return (
    <>
      <section
        id="jamlogPage"
        className="w-full h-full xs:h-full pt-0  flex flex-col items-start xs:px-5  px-[112px] lg:px-10 md:px-4 sm:px-6 xs:hidden"
      >
        <div className="flex flex-col w-1/2 xs:w-full">
          <div className="flex flex-col w-full items-start xs:mt-6">
            <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[24px] text-[#30323D] font-medium">
              {t("choose:why")}
            </p>
            <p className="text-[40px] xs:text-[20px] md:text-[28px] sm:text-[24px] text-[#30323D] font-bold">
              {t("choose:chooseUs")}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center w-full xs:flex-col">
          <div className="flex flex-col items-start gap-4 xs:mt-6">
            {jamlogData?.map((item, index) => {
              return (
                <>
                  <div className="flex flex-row pt-2 h-[82px]">
                    <div className="flex items-center justify-center md:w-8 xs:w-6 xs:h-6 sm:w-6">
                      <item.icon />
                    </div>
                    <div className="flex flex-col ml-4 mt-4 xs:mt-0 ">
                      <p className="text-[16px] xs:text-[14px] sm:text-[14px] leading-[30px] xs:leading-5 font-semibold text-[#30323D] w-[100%] lg:w-[80%] md:w-[70%]">
                        {item.title}
                      </p>
                      <p className="text-[14px] xs:text-[12px] sm:text-xs xs:w-[90%] text-[#404041] font-light w-[100%]">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <img
            src={JamlogEquipmentCard}
            alt="JamlogEquipmentCard"
            loading="lazy"
            style={{
              width: "40%",
              height: "auto",
            }}
          />
        </div>
        <div className="flex flex-row mt-12 xs:flex-col-reverse justify-between items-center   w-full xs:items-start xs:mt-6 sm:mt-12">
          <img
            src={JamunitImage}
            alt="JamunitImage"
            loading="lazy"
            style={{
              width: "40%",
              height: "auto",
            }}
          />
          <div className="flex flex-col items-start gap-4">
            {data?.map((datas, index) => {
              return (
                <>
                  <div className="flex flex-row pt-2 h-[82px]">
                    <div className="flex items-center justify-center md:w-8  xs:w-6 xs:h-6 sm:w-6">
                      <datas.icons />
                    </div>
                    <div className="flex flex-col ml-4 mt-4 xs:mt-0 ">
                      <p className="text-[16px] sm:text-[14px] xs:text-[14px] sm:leading-5 xs:leading-5  leading-[30px] font-semibold text-[#30323D] w-[90%] lg:w-[80%] md:w-full">
                        {datas.titles}
                      </p>
                      <p className="text-[14px] sm:text-xs xs:text-[12px] text-[#404041] font-light w-[100%] ">
                        {datas.subtitles}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {/* {mobile view} */}
      <div
        id="jamlogPage"
        className="hidden flex-col w-full h-full xs:flex items-start"
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full items-start mt-6 px-5">
            <p className="text-[20px]  text-[#30323D] font-medium">
              {t("choose:why")}
            </p>
            <p className="text-[20px]  text-[#30323D] font-bold">
              {t("choose:chooseUs")}
            </p>
          </div>
        </div>

        <div className="flex flex-col  items-start w-full px-5">
          <div className="flex flex-col items start mt-6">
            {jamlogData?.map((item, index) => {
              return (
                <>
                  <div className="flex flex-row pt-2 h-[82px]">
                    <div className="flex items-center justify-center w-6 h-6">
                      <item.icon />
                    </div>
                    <div className="flex flex-col ml-4 ">
                      <p className="text-[14px] leading-5 font-semibold text-[#30323D] w-[90%]">
                        {item.title}
                      </p>
                      <p className="text-[12px] w-[90%] text-[#404041] font-light ">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <img src={JamlogEquipmentCard} alt="JamlogKitImage" className="" />
        </div>
        <div className="flex flex-col-reverse justify-between  w-full items-start mt-6 px-5">
          <img src={JamunitImage} alt="JamunitImage" className="mt-6 px-5" />
          <div className="flex flex-col items start">
            {data?.map((datas, index) => {
              return (
                <>
                  <div className="flex flex-row pt-2 h-[82px]">
                    <div className="flex items-center justify-center w-6 h-6">
                      <datas.icons />
                    </div>
                    <div className="flex flex-col ml-4 xs:mt-0 ">
                      <p className="text-[14px] leading-5 font-semibold text-[#30323D] w-[90%]">
                        {datas.titles}
                      </p>
                      <p className="text-[12px] text-[#404041] font-light w-[100%]">
                        {datas.subtitles}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default JamlogPage;
