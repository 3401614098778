import React from "react";
import {
  AboutImage,
  AboutJamlogNew,
  HomeAboutImage,
  UpuJamlogAbout,
} from "../assets";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  return (
    <>
      <section
        style={{}}
        id="page"
        className={`w-full h-full  px-[112px] sm:px-8 md:px-12 lg:px-[80px] pt-[80px]  flex flex-col items-start relative bg-white xs:hidden`}
      >
        <div className="flex flex-col w-full h-[45%] items-start">
          <div className="flex flex-row items-center gap-1 w-full">
            <p className="text-[40px] md:text-[28px]  text-[#30323D] font-bold">
              upu.
            </p>
            <p className="text-[40px] md:text-[28px] text-[#008999] font-bold">
              jamlog
            </p>
          </div>
          <p className="text-[40px] md:text-[28px] text-[#30323D] font-medium">
            {t("about:abouts")}
          </p>
          <div className="flex flex-col w-[65%] sm:w-[100%] md:w-[100%] lg:w-[90%] mt-2 items-start">
            <p className="text-[20px] text-[#404041] sm:text-[16px] font-light">
              {t("about:aboutDesc")}
            </p>
          </div>
        </div>
        <figure className="w-full">
          <img
            src={AboutJamlogNew}
            alt="aboutJamlogNew"
            loading="lazy"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </figure>
      </section>
      {/* ------------Mobile View-------------------- */}
      <div
        id="page"
        className="flex-col hidden xs:flex item-start w-full h-full py-6"
      >
        <div className="flex flex-row items-center gap-1 w-full px-5">
          <p className="text-[20px]  text-[#30323D] font-bold">upu.</p>
          <p className="text-[20px]  text-[#008999] font-bold">jamlog</p>
        </div>
        <div className="flex items-start px-5">
          <p className="text-[20px]  text-[#30323D] font-medium">
            {t("about:abouts")}
          </p>
        </div>
        <div className="flex flex-col w-full   mt-2 items-start px-5">
          <p className="text-[12px] text-[#404041]  font-light">
            {t("about:aboutDesc")}
          </p>
        </div>
        <img src={AboutImage} alt="AboutImage" className="left-0 mt-4" />
      </div>
    </>
  );
};

//aaa

export default Page;
